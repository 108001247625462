.Category {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

.Card {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  width: 18rem;
  max-height: 40rem;
}

.img {
  object-fit: contain;
}

.Card > * {
  height: 100%;
}

.Card > .card-footer {
  height: 40px;
}
